.l-protected__navbar
   z-index: 1
   position: absolute !important
   left: 0
   width: calc(100% - 50px)
   height: 53px
   border-bottom: 2px solid var(--color-main)
   background: var(--color-white)
   display: flex
   justify-content: space-between
   align-items: center
   padding-left: 50px

.l-protected__main-page
   padding-top: 46px
   box-sizing: border-box

.l-protected__navbar-brand
   width: 40px
   height: 40px
   display: flex
   align-items: center
   justify-content: center
   padding-left: 10px

.l-protected__navbar-content
   width: calc(100% - 50px)
   padding-left: 30px
   padding-right: 30px
   display: flex
   justify-content: space-between
   align-items: center
   box-sizing: border-box

.l-protected__navbar-title
   max-width: calc(100% - 105px)
   color: var(--color-main)
   overflow: hidden
   line-height: 1
   font-weight: 500
   font-size: 18px
   height: 20px
   white-space: nowrap
   text-overflow: ellipsis
   overflow: hidden
   text-align: end

.l-protected__navbar-close
   display: flex
   align-items: center
   margin: 0
   padding: 0
   cursor: pointer
   height: 40px
   border: none
   color: var(--color-main)
   padding: 2px 5px
   background: var(--color-white)

.l-protected__navbar-close:hover
   background: var(--color-bg-button)

.l-protected__navbar-close span
   font-size: 14px
   margin-right: 10px

.l-protected__navbar-close-icon
   height: 16px
   width: 16px

.l-protected
   background: var(--color-white)
   display: flex
   overflow: hidden

.l-protected__page
   width: 100%
   height: 100vh
   overflow-y: auto
   overflow-x: hidden
   position: relative
   background: var(--color-main-page)

.l-protected__main-page
   display: flex
   align-items: center
   position: relative
   flex-direction: column
   overflow: auto
   height: 100%
   > [class^="p"]
      height: 100%
      width: 100%
      max-width: 1200px

.l-protected__content
   padding: 30px
   box-sizing: border-box
   width: 100%
   height: 100%
   max-width: 1440px

@media only screen and (min-width: 992px)
   .l-protected__navbar
      width: 100%
      padding-left: 0

@media only screen and (max-width: 991px)
   .l-protected__bg.--open
      width: 100vw
      height: 100vh
      background: var(--color_bg_menu)
      transition: ease .3s background
      position: absolute
      z-index: 9
   .l-protected__page
      position: initial

@media only screen and (max-width: 730px)
   .l-protected
      &__page
         background: var(--color-white)
      &__content
         padding: 20px 0
@media only screen and (max-width: 543px)
   .l-protected
      &__navbar-content
         padding: 20px
   .l-protected__main-page
      padding-left: 0
   .l-protected__navbar
      width: 100vw
      padding-left: 0

   .l-protected__navbar-content
      padding-left: 10px
   .l-protected__navbar-close
      justify-content: center
      width: 60px
      background: var(--color-bg-button)
   .l-protected__navbar-close span
      display: none
   .l-protected__navbar-title
      max-width: calc(100% - 65px)
      font-size: 16px

