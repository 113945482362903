.c-loading__parent
   position: fixed
   width: 100%
   height: 100%
   top: 0
   left: 0
   background: var(--color_bg_menu)
   font-size: calc(10px + 1vmin)
   z-index: 10000

.c-loading__container
   padding: 20px
   height: 100%
   width: 100%
   box-sizing: border-box
   display: flex
   align-items: center
   justify-content: center

.c-loading__content
   display: flex
   align-items: center

.c-loading__title
   line-height: 35px
   margin-left: 10px
   color: white
