.c-spinner
   position: relative

.c-spinner__double-bounce1
   width: 100%
   height: 100%
   border-radius: 50%
   background-color: var(--color_bg_spinner)
   opacity: 0.6
   position: absolute
   top: 0
   left: 0
   -webkit-animation: sk-bounce 2.0s infinite ease-in-out
   animation: sk-bounce 2.0s infinite ease-in-out

.c-spinner__double-bounce2
   width: 100%
   height: 100%
   border-radius: 50%
   background-color: var(--color_bg_spinner)
   opacity: 0.6
   position: absolute
   top: 0
   left: 0
   -webkit-animation: sk-bounce 2.0s infinite ease-in-out
   animation: sk-bounce 2.0s infinite ease-in-out
   -webkit-animation-delay: -1.0s
   animation-delay: -1.0s


@keyframes sk-bounce
   0%, 100%
      transform: scale(0)

   50%
      transform: scale(1)


.c-spinner__load
   width: 70px
   text-align: center

   > div
      width: 18px
      height: 18px
      background-color: var(--color_bg_spinner)
      border-radius: 100%
      display: inline-block
      -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both
      animation: sk-bouncedelay 1.4s infinite ease-in-out both

   .c-spinner__bounce1
      -webkit-animation-delay: -0.32s
      animation-delay: -0.32s

   .c-spinner__bounce2
      -webkit-animation-delay: -0.16s
      animation-delay: -0.16s

@keyframes sk-bouncedelay
   0%, 80%, 100%
      transform: scale(0)
   40%
      transform: scale(1)

.c-spinner__ring
   display: inline-block
   position: relative
   height: initial !important
   display: flex
   align-items: center
   justify-content: center
   div
      box-sizing: border-box
      display: block
      position: absolute
      margin: 8px
      border: 2px solid #fff
      border-radius: 50%
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite
      border-color: #fff transparent transparent transparent

      &:nth-child(1)
         animation-delay: -0.45s

      &:nth-child(2)
         animation-delay: -0.3s

      &:nth-child(3)
         animation-delay: -0.15s

@keyframes lds-ring
   0%
      transform: rotate(0deg)

   100%
      transform: rotate(360deg)
