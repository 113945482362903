@import "../../../toolbox/styles/_color"

.Modal
   position: absolute,
   top: 50%,
   left: 50%,
   transform: translate(-50%, -50%),
   height: auto,
   width: 40%,
   background-color: #F3F3F3,
   padding: 20px 24px,
   border-radius: 24px

@media screen and (max-width: 1024px)
   .Modal
      width: 50%

@media screen and (max-width: 600px)
   .Modal
      width: 70%
